import { createApp } from 'vue'
import {router} from './router'
// import { Toast } from 'vant';
import App from './App.vue'
import "vant/lib/index.css"

const app = createApp(App)
app.use(router)
// app.use(Toast)
app.mount('#app')
