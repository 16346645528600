import axios from "axios"

const http = axios.create({
    // baseURL: location.href.includes('http://localhost') ? 'http://127.0.0.1:3005' : ''
    baseURL: location.href.includes('http://localhost') ? 'http://119.23.70.219:8099' : ''
    // baseURL: ''
})
export const getQyByIdCard =(params) => {
    return http.get('/admin/index/api/v1/getQyByIdCard', {
        params: {
            page: 1,
            perPage: 20,
            cerno: params.cerno,
            fa_ren_name: params.name,
            phone: params.phone || ''
        }
    })
}
export const getCode =(params) => {
    return http.get('/admin/index/api/v1/getCode', {
        params: {
           id: params.id
        }
    })
}
export const syncQyList =(names) => {
    return http.post('/admin/index/api/v1/syncQyList', {
           names
    })
}

export const queryForm = (filters, form_id) => {
    return http.post('/admin/index/api/v1/searchForm', {
        form_id,
        filters
    }, {
        headers: {
            "Content-Type" : "application/json",
        }
    })
}
export const createJsFormData = (data) => {
    return http.post('/admin/index/createJsForm', {
        ...data
    }, {
        headers: {
            "Content-Type" : "application/json",
        }
    })
}
export const getContract = (entryId) => {
    return http.post('/admin/index/api/v1/getContract', {
        entryId
    }, {
        headers: {
            "Content-Type" : "application/json",
        }
    })
}
export const getSignUrl = (entryId) => {
    return http.get('/admin/index/api/v1/getSignUrl', {
       params: {
           entryId
       },
        headers: {
            "Content-Type" : "application/json",
        }
    })
}

export const dianzi = (ids) => {
    return http.post('/admin/index/api/v1/dianzi', {
        ids
    }, {
        headers: {
            "Content-Type" : "application/json",
        }
    })
}