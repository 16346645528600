import { createWebHashHistory, createRouter } from 'vue-router'

import HomeView from './views/Home.vue'
import ListView from './views/List.vue'

const routes = [
    { path: '/', component: HomeView },
    { path: '/list', component: ListView },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
})